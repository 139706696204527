import { useLocation } from 'react-router-dom';
import { FormMapper } from '../core/mappers/FormMapper';

export const useEditItem = (
  idProperty: string,
  itemService: any,
  inputs: any
) => {
  const { state: item } = useLocation();

  if (!item) {
    return {
      onSubmit: (data: any) =>
        itemService.create(FormMapper.toDTO(data, inputs)),
      defaultValues: undefined,
    };
  }

  const onSubmit = (data: any) => {
    const item = FormMapper.toDTO(data, inputs, idProperty);
    return itemService.update(item);
  };
  const defaultValues = FormMapper.toFormInputs(inputs, item, idProperty);
  return { onSubmit, defaultValues };
};
