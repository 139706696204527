import {
  AxiosError,
  AxiosHeaders,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import { logoutUser } from '../../utils';
import { authService } from '../services';

export class HttpClient {
  private client: AxiosInstance;

  constructor(client: AxiosInstance) {
    this.client = client;
    this.client.interceptors.request.use(
      this.requestInterceptor,
      this.requestErrorInterceptor
    );
    this.client.interceptors.response.use(
      this.responseInterceptor,
      this.responseErrorInterceptor
    );
  }

  async requestInterceptor(config: InternalAxiosRequestConfig) {
    if (!authService.isUserAuthenticated()) {
      await authService.refreshToken();
    }

    const accessToken = localStorage.getItem('accessToken');

    if (accessToken && config?.headers) {
      config.headers['access-token'] = `${accessToken}`;
    }

    return config;
  }

  requestErrorInterceptor(error: AxiosError) {
    return Promise.reject(error);
  }

  responseInterceptor(response: AxiosResponse) {
    return response;
  }

  async responseErrorInterceptor(error: AxiosError) {
    const originalRequest = { _retry: false, ...error.config };

    if (error?.response?.status === 401 && !originalRequest._retry) {
      try {
        await authService.refreshToken();
        const token = localStorage.getItem('accessToken');

        originalRequest.headers = new AxiosHeaders({
          ...originalRequest.headers,
          accessToken: `${token}`,
        });

        originalRequest._retry = true;

        return this.client(originalRequest);
      } catch (err: any) {
        logoutUser();
      }
    }
  }

  get(url: string, config: AxiosRequestConfig) {
    return this.client.get(url, config);
  }

  post(url: string, data: any, config: AxiosRequestConfig) {
    return this.client.post(url, data, config);
  }

  put(url: string, data: any, config: AxiosRequestConfig) {
    return this.client.put(url, data, config);
  }

  patch(url: string, data: any, config: AxiosRequestConfig) {
    return this.client.patch(url, data, config);
  }

  delete(url: string, config: AxiosRequestConfig) {
    return this.client.delete(url, config);
  }
}
