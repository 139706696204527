import { AxiosInstance } from 'axios';
import { getRedirectURi, logoutUser } from '../../utils';
import { isExpired } from 'react-jwt';

export class AuthService {
  client: AxiosInstance;

  constructor(client: AxiosInstance) {
    this.client = client;
  }

  canRefreshToken() {
    const idToken = localStorage.getItem('idToken');
    const refreshtoken = localStorage.getItem('refreshToken');

    if (!idToken || !refreshtoken) {
      return false;
    }

    return true;
  }

  isUserAuthenticated() {
    const idToken = localStorage.getItem('idToken');
    const refreshtoken = localStorage.getItem('refreshToken');

    if (!idToken || !refreshtoken || isExpired(idToken)) {
      return false;
    }

    return true;
  }

  async refreshToken() {
    const idToken = localStorage.getItem('idToken');
    const refreshtoken = localStorage.getItem('refreshToken');

    if (!idToken || !refreshtoken) {
      return false;
    }

    try {
      const { data } = await this.client.post(
        '/refresh',
        {},
        {
          headers: {
            refreshtoken,
          },
        }
      );

      const { access_token: newAccessToken, id_token: newIdToken } = data;

      this.storeToken(newAccessToken, refreshtoken, newIdToken);
      return true;
    } catch (error: any) {
      console.log(error);
      logoutUser();
      return false;
    }
  }

  async login(code: string) {
    try {
      const { data } = await this.client.get('/access', {
        headers: {
          code,
          redirect_uri: decodeURIComponent(getRedirectURi()),
        },
      });

      this.storeToken(data.access_token, data.refresh_token, data.id_token);
      return data;
    } catch (error: any) {
      console.log(error);
      return null;
    }
  }

  storeToken(
    accessToken: string | undefined,
    refreshToken: string,
    idToken: string
  ) {
    localStorage.removeItem('accessToken');
    localStorage.setItem('refreshToken', refreshToken);
    localStorage.setItem('idToken', idToken);

    if (accessToken) {
      localStorage.setItem('accessToken', accessToken);
    }
  }
}
