import axios from 'axios';
import { HttpClient } from './HttpClient';

const axiosClient = axios.create({
  baseURL: `https://${import.meta.env.VITE_BO_DOMAIN}/api`,
  withCredentials: import.meta.env.MODE !== 'development',
});

const httpClient = new HttpClient(axiosClient);

export { httpClient };
