import { Control, Controller, UseFormRegister, useFieldArray } from "react-hook-form"
import Input from "../../../components/Input";
import { useEffect } from "react";
import Select from "../../../components/Select";

interface Props {
  control: Control;
  defaultValues: any;
  register: UseFormRegister<any>;
};

const MeasuresForm = ({ control, register, defaultValues }: Props) => {
  const { fields, append, remove } = useFieldArray<any>({
    control,
    name: "measurements"
  });

  useEffect(() => {
    if (defaultValues?.measurements) {
      return;
    }

    append({});
  }, []);

  const options = [
    { label: 'g', value: 'g' },
    { label: 'ml', value: 'ml' }
  ]

  return <div className="flex gap-5.5 w-full">
    <div className="flex flex-col mb-3" >
      <label
        className="mb-3 block text-sm font-medium text-black dark:text-white"
        htmlFor={`measurements_portion`}
      >
        Porción típica
      </label>
      <Controller
        render={({ field }) => <Input defaultValue={defaultValues ? defaultValues.measurements_portion : undefined} required={true} type={'number'} {...field} />}
        name={`measurements_portion`}
        control={control}
      />
    </div>
    <div className="flex flex-col w-full mb-3" >
      <label
        className="mb-3 block text-sm font-medium text-black dark:text-white"
        htmlFor={`measurements_unit`}
      >
        Unidad
      </label>
      <Select control={control} options={options} {...register<any>(`measurements_unit`)} />
    </div>
    <div className="flex flex-col">
      {fields.map((_, index) => (
        <div className="flex flex-col gap-5.5 w-full">
          <div className="flex flex-col" >
            <label
              className="mb-3 block text-sm font-medium text-black dark:text-white"
              htmlFor={`measurements.${index}`}
            >
              Cantidad
            </label>
            <Controller
              render={({ field }) => <Input required={true} type={'number'} {...field} />}
              name={`measurements.${index}`}
              control={control}
            />
          </div>
          {index > 0 && <button
            className=" rounded bg-primary py-2 px-6 font-medium text-gray hover:shadow-1"
            type="button"
            onClick={() => remove(index)}
          >
            Eliminar
          </button>}

        </div>
      ))}
    </div>
    <div className="flex max-h-10 items-center">

      <button
        className=" justify-center rounded bg-primary py-2 px-6 font-medium text-gray hover:shadow-1"
        onClick={() => append({})}
        type="button"
      >
        Añadir
      </button>
    </div>
  </div>
}

export default MeasuresForm;