import { Control, Controller, UseFormRegister, UseFormWatch, useFieldArray } from "react-hook-form"
import { useIngredientsOptions } from "../../hooks/useIngredientsOptions";
import Input from "../../../components/Input";
import Select from "../../../components/Select";
import { getFoodNameFromId } from "../../../utils";
import { useEffect, useState } from "react";

interface Props {
  control: Control;
  register: UseFormRegister<any>;
  watch: UseFormWatch<any>;
  defaultValues: any;
};

const IngredientsForm = ({ control, register, defaultValues }: Props) => {
  const { options } = useIngredientsOptions();
  const { fields, append, remove } = useFieldArray<any>({
    control,
    name: "ingredients"
  });

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (options.length) {
      setLoading(false);
    }

  }, [options])

  useEffect(() => append({ key: '', value: '' }), [])

  if (loading) {
    return null;
  }

  return <div>{fields.map((_, index) => {
    if (defaultValues?.ingredients.length > index) {
      const defaulSelecttValue = {
        label: getFoodNameFromId(defaultValues['ingredients'][index].key?.value, options),
        value: defaultValues['ingredients'][index].value
      };
      const defaulInputtValue = defaultValues['ingredients'][index][1];

      return <div className="flex gap-5.5 w-full">
        <div className="flex flex-col  w-full mb-4" >
          <Select control={control} options={options} defaultValues={defaulSelecttValue} {...register<any>(`ingredients.${index}.key`)} />
        </div>
        <div className="flex flex-col mb-4" >
          <Controller
            render={({ field }) => <Input defaultValue={defaulInputtValue} type={'number'} step={0.01} {...field} />}
            name={`ingredients.${index}.value`}
            control={control}
          />
        </div>
        <div className="flex flex-col mb-4" >
          <button
            className="flex justify-center rounded bg-primary py-2 px-6 font-medium text-gray hover:shadow-1"
            type="button"
            onClick={() => remove(index)}
          >
            Eliminar
          </button>
        </div>
      </div>
    }

    return <div className="flex gap-5.5 w-full">
      <div className="flex flex-col  w-full mb-4" >
        <Select control={control} options={options} {...register<any>(`ingredients.${index}.key`)} />
      </div>
      <div className="flex flex-col mb-4" >
        <Controller
          render={({ field }) => <Input type={'number'} step={0.01} {...field} />}
          name={`ingredients.${index}.value`}
          control={control}
        />
      </div>
      <div className="flex flex-col mb-4" >
        <button
          className="flex justify-center rounded bg-primary py-2 px-6 font-medium text-gray hover:shadow-1"
          type="button"
          onClick={() => remove(index)}
        >
          Eliminar
        </button>
      </div>
    </div>
  })}
    <button
      className="flex justify-center rounded bg-primary py-2 px-6 font-medium text-gray hover:shadow-1 mb-4"
      onClick={() => append({ key: '', value: '' })}
      type="button"
    >
      Añadir
    </button></div>
}


export default IngredientsForm;