import { createContext, useEffect, useMemo, useState } from "react";
import { foodService } from "../../core/services";
import ConfirmationModal from "../../components/ConfirmationModal";

export const FoodListContext = createContext<any>({
  foods: [],
  setFoods: () => { }
});

export const FoodListProvider = ({ children }: { children: React.ReactNode }) => {
  const [foods, setFoods] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [modal, setModal] = useState({
    itemId: '',
    itemName: '',
    show: false
  });

  const update = () => {
    setIsLoading(true);
    foodService.get().then(({ data }) => data.items.filter(({ valid }: any) => valid !== false)).then((foodList) => {
      setFoods(foodList);
      setIsLoading(false);
    })
  }

  const showModal = (itemId: string, itemName: string) => {
    setModal({ itemId, itemName, show: true });
  }

  const hideModal = () => {
    setModal({ itemId: '', itemName: '', show: false });
  }

  const onModalClick = (itemId: string) => {
    foodService.delete(itemId).then(() => { window.location.reload() });
  }

  useEffect(() => {
    update();
  }, []);

  const contextValue = useMemo(() => ({
    foods,
    isLoading,
    setFoods,
    update,
    showModal,
    hideModal
  }), [foods]);

  return <FoodListContext.Provider value={contextValue}>
    <ConfirmationModal itemId={modal.itemId} itemName={modal.itemName} show={modal.show} onClick={onModalClick} onCancel={hideModal} />
    {children}
  </FoodListContext.Provider>
}