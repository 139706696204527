import { AuthService } from './AuthService';
import axios from 'axios';
import { UserService } from './UserService';
import { httpClient } from '../http';
import { FoodService } from './FoodService';
import { PortionService } from './PortionService';

const authClient = axios.create({
  baseURL: `https://${import.meta.env.VITE_BO_DOMAIN}/api/profile/oauth/token`,
});

const authService = new AuthService(authClient);
const userService = new UserService(httpClient);
const foodService = new FoodService(httpClient);
const portionService = new PortionService(httpClient);

export { authService, userService, foodService, portionService };
