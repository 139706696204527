import { FormInputContent } from '../../../types';

export const inputs: FormInputContent = {
  identification: {
    sectionProperties: {
      sectionType: 'normal',
      sectionGrid: 3,
      sectionGap: 4,
      title: 'Identificación',
    },
    name_specific: {
      label: 'Nombre',
      required: true,
    },
    name_generic: {
      label: 'Nombre general',
      required: true,
    },
    brand: {
      label: 'Marca',
      required: true,
    },
    code_bar: {
      label: 'Código de barra',
      type: 'number',
    },
  },
  sectionProperties: {
    sectionType: 'normal',
    sectionGrid: 3,
    sectionGap: 4,
    title: 'General',
  },
  carbohydrates_total: {
    label: 'Carbohidratos totales',
    type: 'number',
    step: '0.01',
    required: true,
  },
  energy: {
    label: 'Energía',
    type: 'number',
    step: '0.01',
    required: true,
  },
  fats_total: {
    label: 'Grasas totales',
    type: 'number',
    step: '0.01',
    required: true,
  },
  proteins_total: {
    label: 'Proteínas totales',
    type: 'number',
    step: '0.01',
    required: true,
  },
  carbohydrates_detail: {
    sectionProperties: {
      sectionType: 'normal',
      sectionGrid: 3,
      sectionGap: 4,
      title: 'Detalle carbohidratos',
    },
    fiber_insoluble: {
      label: 'Fibras insolubles',
      type: 'number',
      step: '0.01',
    },
    fiber_soluble: {
      label: 'Fibras solubles',
      type: 'number',
      step: '0.01',
    },
    fiber_total: {
      label: 'Fibras totales',
      type: 'number',
      step: '0.01',
    },
    starches: {
      label: 'Almidón',
      type: 'number',
      step: '0.01',
    },
    sugars: {
      label: 'Azúcares',
      type: 'number',
      step: '0.01',
    },
  },
  certifications: {
    sectionProperties: {
      sectionType: 'normal',
      sectionGrid: 3,
      sectionGap: 4,
      title: 'Certificaciones',
    },
    certifications: {
      label: '',
      component: 'multiselect',
      options: [
        { label: 'Libre de gluten', value: 'glutenfree' },
        { label: 'Halal', value: 'halal' },
        { label: 'Kosher', value: 'kosher' },
        { label: 'Libre de lactosa', value: 'lactosefree' },
        { label: 'Crudo', value: 'raw' },
        { label: 'Vegano', value: 'vegan' },
      ],
    },
  },
  contents: {
    sectionProperties: {
      sectionType: 'normal',
      sectionGrid: 3,
      sectionGap: 4,
      title: 'Contenidos',
    },
    contents: {
      label: '',
      component: 'multiselect',
      options: [
        { label: 'Caseína', value: 'allergens_casein' },
        { label: 'Histamina', value: 'allergens_histamine' },
        { label: 'Lupino', value: 'allergens_lupin' },
        { label: 'Sulfitos', value: 'allergens_sulphites' },
        { label: 'Cereales', value: 'cereals' },
        { label: 'Maíz', value: 'cereals_corn' },
        { label: 'Avena', value: 'cereals_oat' },
        { label: 'Soja', value: 'cereals_soy' },
        { label: 'Crustáceos', value: 'crustaceans' },
        { label: 'Grasos', value: 'crustaceans_fatty' },
        { label: 'Magros', value: 'crustaceans_lean' },
        { label: 'Semigrasos', value: 'crustaceans_semifatty' },
        { label: 'Huevos', value: 'eggs' },
        { label: 'Clara', value: 'eggs_white' },
        { label: 'Yema', value: 'eggs_yolk' },
        { label: 'Pescado', value: 'fish' },
        { label: 'Graso', value: 'fish_fatty' },
        { label: 'Magro', value: 'fish_lean' },
        { label: 'Semigraso', value: 'fish_semifatty' },
        { label: 'Carne', value: 'meat' },
        { label: 'Aves de corral', value: 'meat_poultry' },
        { label: 'Pollo', value: 'meat_poultry_chicken' },
        { label: 'Gallina de Cornualles', value: 'meat_poultry_cornishhen' },
        { label: 'Pato', value: 'meat_poultry_duck' },
        { label: 'Ganso', value: 'meat_poultry_goose' },
        { label: 'Gallina de Guinea', value: 'meat_poultry_guineafowl' },
        { label: 'Avestruz', value: 'meat_poultry_ostrich' },
        { label: 'Faisán', value: 'meat_poultry_pheasant' },
        { label: 'Codorniz', value: 'meat_poultry_quail' },
        { label: 'Pavo', value: 'meat_poultry_turkey' },
        { label: 'Carne roja', value: 'meat_red' },
        { label: 'Bisonte', value: 'meat_red_bison' },
        { label: 'Jabalí', value: 'meat_red_boar' },
        { label: 'Ganado', value: 'meat_red_cattle' },
        { label: 'Alce', value: 'meat_red_elk' },
        { label: 'Cabra', value: 'meat_red_goat' },
        { label: 'Canguro', value: 'meat_red_kangaroo' },
        { label: 'Cordero', value: 'meat_red_lamb' },
        { label: 'Cerdo', value: 'meat_red_pork' },
        { label: 'Ternera', value: 'meat_red_veal' },
        { label: 'Venado', value: 'meat_red_venison' },
        { label: 'Carne blanca', value: 'meat_white' },
        { label: 'Rana', value: 'meat_white_frog' },
        { label: 'Conejo', value: 'meat_white_rabbit' },
        { label: 'Caracol', value: 'meat_white_snail' },
        { label: 'Leche', value: 'milk' },
        { label: 'Moluscos', value: 'molluscs' },
        { label: 'Grasos', value: 'molluscs_fatty' },
        { label: 'Magros', value: 'molluscs_lean' },
        { label: 'Semigrasos', value: 'molluscs_semifatty' },
        { label: 'Frutos secos', value: 'nuts' },
        { label: 'Almendras', value: 'nuts_almonds' },
        { label: 'Argán', value: 'nuts_argan' },
        { label: 'Brasil', value: 'nuts_brazil' },
        { label: 'Nuez de la India', value: 'nuts_candlenuts' },
        { label: 'Anacardo', value: 'nuts_cashew' },
        { label: 'Anacardos', value: 'nuts_cashews' },
        { label: 'Cedro', value: 'nuts_cedar' },
        { label: 'Castañas', value: 'nuts_chestnuts' },
        { label: 'Coco', value: 'nuts_coco' },
        { label: 'Cola', value: 'nuts_cola' },
        { label: 'Avellanas', value: 'nuts_hazelnuts' },
        { label: 'Nogal americano', value: 'nuts_hickory' },
        { label: 'Nuez de kukui', value: 'nuts_kukui' },
        { label: 'Macadamias', value: 'nuts_macadamias' },
        { label: 'Otras nueces', value: 'nuts_other' },
        { label: 'Cacahuetes', value: 'nuts_peanuts' },
        { label: 'Nuez pecana', value: 'nuts_pecan' },
        { label: 'Piñón', value: 'nuts_pine' },
        { label: 'Pistachos', value: 'nuts_pistachios' },
        { label: 'Zapote', value: 'nuts_sapote' },
        { label: 'Nuez de sapucaia', value: 'nuts_sapucaia' },
        { label: 'Karité', value: 'nuts_shea' },
        { label: 'Nueces', value: 'nuts_walnuts' },
        { label: 'Semillas', value: 'seeds' },
        { label: 'Mostaza', value: 'seeds_mustard' },
        { label: 'Pimienta', value: 'seeds_pepper' },
        { label: 'Sésamo', value: 'seeds_sesame' },
        { label: 'Azúcares', value: 'sugars' },
        { label: 'Fructosa', value: 'sugars_fructose' },
        { label: 'Sorbitol', value: 'sugars_sorbitol' },
        { label: 'Sacarosa', value: 'sugars_sucrose' },
        { label: 'Verduras', value: 'vegetables' },
        { label: 'Apio', value: 'vegetables_celery' },
      ],
    },
  },
  fats_detail: {
    sectionProperties: {
      sectionType: 'normal',
      sectionGrid: 3,
      sectionGap: 4,
      title: 'Detalle grasas',
    },
    cholesterol: {
      label: 'Colesterol',
      type: 'number',
      step: '0.01',
    },
    monounsaturated: {
      label: 'Monoinsaturadas',
      type: 'number',
      step: '0.01',
    },
    polyunsaturated: {
      label: 'Poliinsaturadas',
      type: 'number',
      step: '0.01',
    },
    saturated: {
      label: 'Saturadas',
      type: 'number',
      step: '0.01',
    },
    trans: {
      label: 'Trans',
      type: 'number',
      step: '0.01',
    },
  },
  minerals: {
    sectionProperties: {
      sectionType: 'normal',
      sectionGrid: 3,
      sectionGap: 4,
      title: 'Minerales',
    },
    ca: {
      label: 'Calcio',
      type: 'number',
      step: '0.01',
    },
    cr: {
      label: 'Cromo',
      type: 'number',
      step: '0.01',
    },
    cu: {
      label: 'Cobre',
      type: 'number',
      step: '0.01',
    },
    fe: {
      label: 'Hierro',
      type: 'number',
      step: '0.01',
    },
    i: {
      label: 'Yodo',
      type: 'number',
      step: '0.01',
    },
    k: {
      label: 'Potasio',
      type: 'number',
      step: '0.01',
    },
    mo: {
      label: 'Molibdeno',
      type: 'number',
      step: '0.01',
    },
    mg: {
      label: 'Magnesio',
      type: 'number',
      step: '0.01',
    },
    mn: {
      label: 'Manganeso',
      type: 'number',
      step: '0.01',
    },
    na: {
      label: 'Sodio',
      type: 'number',
      step: '0.01',
    },
    p: {
      label: 'Fósforo',
      type: 'number',
      step: '0.01',
    },
    se: {
      label: 'Selenio',
      type: 'number',
      step: '0.01',
    },
    zn: {
      label: 'Zinc',
      type: 'number',
      step: '0.01',
    },
  },
  proteins_detail: {
    sectionProperties: {
      sectionType: 'normal',
      sectionGrid: 3,
      sectionGap: 4,
      title: 'Proteínas',
    },
    bcaa: {
      label: 'BCAA',
      type: 'number',
      step: '0.01',
    },
    creatine: {
      label: 'Creatina',
      type: 'number',
      step: '0.01',
    },
    largininge: {
      label: 'L-Arginina',
      type: 'number',
      step: '0.01',
    },
    lcarnitine: {
      label: 'L-Carnitina',
      type: 'number',
      step: '0.01',
    },
    lglutamine: {
      label: 'L-Glutamina',
      type: 'number',
      step: '0.01',
    },
  },
  vitamins: {
    sectionProperties: {
      sectionType: 'normal',
      sectionGrid: 3,
      sectionGap: 4,
      title: 'Vitaminas',
    },
    a: {
      label: 'Vitamina A',
      type: 'number',
      step: '0.01',
    },
    b1: {
      label: 'Vitamina B1',
      type: 'number',
      step: '0.01',
    },
    b12: {
      label: 'Vitamina B12',
      type: 'number',
      step: '0.01',
    },
    b2: {
      label: 'Vitamina B2',
      type: 'number',
      step: '0.01',
    },
    b3: {
      label: 'Vitamina B3',
      type: 'number',
      step: '0.01',
    },
    b5: {
      label: 'Vitamina B5',
      type: 'number',
      step: '0.01',
    },
    b6: {
      label: 'Vitamina B6',
      type: 'number',
      step: '0.01',
    },
    b7: {
      label: 'Vitamina B7',
      type: 'number',
      step: '0.01',
    },
    b9: {
      label: 'Vitamina B9',
      type: 'number',
      step: '0.01',
    },
    c: {
      label: 'Vitamina C',
      type: 'number',
      step: '0.01',
    },
    d: {
      label: 'Vitamina D',
      type: 'number',
      step: '0.01',
    },
    e: {
      label: 'Vitamina E',
      type: 'number',
      step: '0.01',
    },
    k: {
      label: 'Vitamina K',
      type: 'number',
      step: '0.01',
    },
  },
  measurements: {
    sectionProperties: {
      sectionType: 'measuresForm',
      sectionGrid: 4,
      sectionGap: 4,
      title: 'Tamaños',
    },
  },
  others: {
    sectionProperties: {
      sectionType: 'others',
      title: 'Otros',
    },
  },
};
