
import { createPortal } from 'react-dom';

interface Props {
  itemId: string;
  itemName: string;
  show: boolean;
  onClick: any;
  onCancel: any;
}

const ConfirmationModal = ({ itemId, itemName, show, onClick, onCancel }: Props) => {

  if (!show) {
    return null;
  }

  return createPortal(
    <div id="dialog" className="fixed left-0 top-0 bg-black  w-screen h-screen transition-opacity duration-500 bg-opacity-65" >
      <div className="bg-white rounded shadow-md p-8 mx-auto my-20 w-1/4">
        <div className="flex items-center gap-5">
          <div className="bg-red-200 rounded-full p-5 text-red-500 flex items-center justify-center w-10 h-10">
          </div>
          <div>
            <h1 className="font-bold text-lg mb-2">Eliminar item</h1>
            <p>¿Desea eliminar el item {itemName}?</p>
          </div>
        </div>
        <div className="flex justify-end gap-4 mt-5">
          <button onClick={onCancel} className="bg-gray-100 border border-gray-300 px-6 py-2 rounded text-black hover:bg-gray-200" >Cancelar</button>
          <button onClick={() => onClick(itemId)} className="bg-red-500 px-6 py-2 rounded text-white hover:bg-red-600">Eliminar</button>
        </div>
      </div>
    </div>,
    document.body
  )
}

export default ConfirmationModal