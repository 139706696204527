import { HttpClient } from '../http/HttpClient';

export class PortionService {
  private client: HttpClient;

  constructor(client: HttpClient) {
    this.client = client;
  }

  create(portion: any) {
    return this.client.put('/service/nutrition/portion/item', portion, {});
  }

  get(pid?: string) {
    const queryParams = pid ? `?pid=${pid}` : '';
    return this.client.get(`/service/nutrition/portion/list${queryParams}`, {});
  }

  delete(pid?: string) {
    const headers = { pid };
    return this.client
      .delete(`/service/nutrition/portion/item`, { headers })
      .then(({ data }) =>
        this.client.delete(`/service/nutrition/portion/item`, {
          headers: {
            pid,
            validation: data.validation,
          },
        })
      );
  }

  update(portion: any) {
    return this.client.patch('/service/nutrition/portion/item', portion, {});
  }
}
