import { HttpClient } from '../http/HttpClient';

export class FoodService {
  private client: HttpClient;

  constructor(client: HttpClient) {
    this.client = client;
  }

  create(food: any) {
    return this.client.put('/service/nutrition/food/item', food, {});
  }

  get(fid?: string) {
    const queryParams = fid ? `?fid=${fid}` : '';
    return this.client.get(`/service/nutrition/food/list${queryParams}`, {});
  }

  delete(fid?: string) {
    const headers = { fid };
    return this.client
      .delete(`/service/nutrition/food/item`, { headers })
      .then(({ data }) =>
        this.client.delete(`/service/nutrition/food/item`, {
          headers: {
            fid,
            validation: data.validation,
          },
        })
      );
  }

  update(food: any) {
    return this.client.patch('/service/nutrition/food/item', food, {});
  }
}
