import BasicForm from '../../../components/BasicForm';
import { foodService } from '../../../core/services';
import { inputs } from './inputs';
import { useEditItem } from '../../../hooks/useEditItem';

const FoodRegistry = () => {

  const { onSubmit, defaultValues } = useEditItem('fid', foodService, inputs);
  return <BasicForm
    title={'Registro de alimentos'}
    inputs={inputs}
    onSubmit={onSubmit}
    defaultValues={defaultValues}
  />;
};

export default FoodRegistry;