import { useContext, useMemo } from "react";
import { UseFormWatch } from "react-hook-form";
import { FoodListContext } from "../../providers/FoodListProvider";
import { nutritionOperations } from 'weelock-service-utils'

type Props = {
  watch: UseFormWatch<any>;
}

const Resume = ({ watch }: Props) => {
  const values = watch();
  const { foods } = useContext(FoodListContext);

  const data = useMemo(() => {
    if (!values.ingredients?.length) {
      return;
    }

    const nutritionalInformation = values.ingredients.map(({ key, value }: any) => {
      const food = foods.find(({ fid }: any) => fid === key.value);

      if (!food || value === '') {
        return undefined;
      }

      return [food, parseFloat(value)]
    });

    if (nutritionalInformation.includes(undefined)) {
      return;
    }

    return nutritionOperations.nutritionWeightedSum(nutritionalInformation);

  }, [values]);

  const ingredients = values.ingredients;
  if (!ingredients) {
    return null;
  }

  return <div className="grid grid-cols-2">
    <div>Energía: {data?.energy}</div>
    <div>Proteínas totales: {data?.proteins_total}</div>
    <div>Carbohidratos totales: {data?.carbohydrates_total}</div>
    <div>Grasas totales: {data?.fats_total}</div>
  </div>
}

export default Resume;