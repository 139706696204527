import { useForm } from "react-hook-form"
import { BasicFormComponents, BasicFormForms, FormInput, FormInputContent, FormSection, SectionProperties } from "../../types";
import TextArea from "../TextArea";
import Input from "../Input";
import Select from "../Select";
import MultiSelect from "../MultiSelect";
import Form from "./Form";
import OthersForm from "../../service/views/FoodRegistry/OthersForm";
import IngredientsForm from "../../service/views/PortionsRegistry/IngredientsForm";
import MealsForm from "../../service/views/PortionsRegistry/MealsForm";
import SizesForm from "../../service/views/PortionsRegistry/SizesForm";
import Resume from "../../service/views/PortionsRegistry/Resume";
import { getFormSectionData } from "../../utils";
import MeasuresForm from "../../service/views/FoodRegistry/MeasuresForm";

type Props = {
  title: string;
  inputs: FormInputContent,
  onSubmit: Function,
  defaultValues: any;
}

const BasicForm = ({ title, inputs, onSubmit, defaultValues }: Props) => {
  const { register, handleSubmit, formState, control, watch } = useForm({
    defaultValues
  });
  const { isSubmitting } = formState;

  const components: BasicFormComponents = {
    input: Input,
    textarea: TextArea,
    multiselect: MultiSelect,
    mealselect: MultiSelect,
    select: Select
  }

  const forms: BasicFormForms = {
    sizesForm: SizesForm,
    others: OthersForm,
    normal: Form,
    ingredientsForm: IngredientsForm,
    mealsForm: MealsForm,
    measuresForm: MeasuresForm,
    resume: Resume,
  };

  const inputsArray = Object.entries<FormSection | FormInput | SectionProperties>(inputs);

  return <div className="mx-auto">
    <div className="grid">
      <div className="col-span-5 xl:col-span-3">
        <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
          <div className="border-b border-stroke py-4 px-7 dark:border-strokedark">
            <h3 className="font-medium text-black dark:text-white">
              {title}
            </h3>
          </div>
          <div className="p-7">
            <form onSubmit={handleSubmit((data) => onSubmit(data))}>
              {
                inputsArray
                  .filter(([, value]) => 'sectionGrid' in value || 'sectionProperties' in value)
                  .map(([, values]: [string, FormSection | SectionProperties]) => {

                    const { sectionGrid, sectionGap, sectionType, title, content } = getFormSectionData(values, inputsArray);

                    return <>
                      <div className="text-2xl mb-5">{title}</div>
                      <div className={`grid grid-cols-${sectionGrid ?? 1} gap-${sectionGap ?? 4}`}>
                        {forms[sectionType]({ content, components, register, control, watch, defaultValues })}
                      </div>
                    </>
                  })
              }

              <div className="flex justify-end gap-4.5">
                <button
                  disabled={isSubmitting}
                  className="flex justify-center rounded bg-primary py-2 px-6 font-medium text-gray hover:shadow-1"
                  type="submit"
                >
                  Guardar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
}


export default BasicForm;