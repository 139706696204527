import { Control, Controller, useFieldArray } from "react-hook-form";
import Input from "../../../components/Input";

interface Props {
  control: Control;
};

const OthersForm = ({ control }: Props) => {

  const { fields, append, remove } = useFieldArray<any>({
    control,
    name: "others"
  });

  return <div>
    {fields.map((_, index) => (
      <div className="mb-3 flex gap-5.5 w-full">
        <div className="flex flex-col" >
          <label
            className="mb-3 block text-sm font-medium text-black dark:text-white"
            htmlFor={`others.${index}.key`}
          >
            Llave
          </label>
          <Controller
            render={({ field }) => <Input {...field} pattern="^[a-z_]+$" />}
            name={`others.${index}.key`}
            control={control}
          />
        </div>
        <div className="flex flex-col" >
          <label
            className="mb-3 block text-sm font-medium text-black dark:text-white"
            htmlFor={`others.${index}.name`}
          >
            Nombre
          </label>
          <Controller
            render={({ field }) => <Input {...field} />}
            name={`others.${index}.name`}
            control={control}
          />
        </div>
        <div className="flex flex-col" >
          <label
            className="mb-3 block text-sm font-medium text-black dark:text-white"
            htmlFor={`others.${index}.value`}
          >
            Cantidad
          </label>
          <Controller
            render={({ field }) => <Input {...field}
            />}
            name={`others.${index}.value`}
            control={control}
          />
        </div>
        <button
          className=" rounded bg-primary py-2 px-6 font-medium text-gray hover:shadow-1"
          type="button"
          onClick={() => remove(index)}
        >
          Eliminar
        </button>
      </div>
    ))}
    <button
      className="flex justify-center rounded bg-primary py-2 px-6 font-medium text-gray hover:shadow-1"
      onClick={() => append({ key: '', value: '' })}
      type="button"
    >
      Añadir
    </button>


  </div >
}
export default OthersForm;