import { createColumnHelper } from '@tanstack/react-table';
import { BasicFormForms, FormInputContent } from '../../types';
import {
  getBaseInputs,
  ingredientsToString,
  mealsToString,
  parseIngredientsFormInputs,
  parseMealsFormInputs,
  parseNormalInputs,
  parseOthersFormInputs,
  parseSelectInputs,
  parseSizesFormInputs,
  parseBaseInputsDTO,
  sizesToString,
  parseNormalInputsDTO,
  parseIngredientsFormDTO,
  parseMealsFormDTO,
  parseSizesFormDTO,
  parseResumeFormDTO,
  parseOthersFormDTO,
  parseMeasuresFormDTO,
  parseMeasurementsFormInputs,
  measuresToString,
} from '../../utils';

export class FormMapper {
  static toDTO(raw: any, inputs: FormInputContent, idProperty?: string) {
    const parserMapper = {
      normal: parseNormalInputsDTO,
      ingredientsForm: parseIngredientsFormDTO,
      mealsForm: parseMealsFormDTO,
      sizesForm: parseSizesFormDTO,
      resume: parseResumeFormDTO,
      others: parseOthersFormDTO,
      measuresForm: parseMeasuresFormDTO,
    };
    const item = Object.entries(inputs)
      .filter(([key]) => key !== 'sectionProperties')
      .reduce(
        (res, [key, values]) => {
          if (values.label !== undefined && raw[key] !== '') {
            return parseBaseInputsDTO(res, raw, key, values);
          }

          if (values.sectionProperties === undefined) {
            return res;
          }

          const { sectionType } = values.sectionProperties;
          const parser = parserMapper[sectionType as keyof BasicFormForms];

          if (parser) {
            return parser(res, raw, key, values);
          }

          return res;
        },
        idProperty ? { [idProperty]: raw[idProperty] } : {}
      );

    item.timestamp = raw.timestamp;
    return {
      item,
    };
  }

  static toFormInputs(inputs: FormInputContent, item: any, idProperty: string) {
    const inputsArray = Object.entries(inputs);

    const baseInputs = getBaseInputs(inputsArray, item);
    const normalInputs = parseNormalInputs(inputsArray, item);
    const selectInputs = parseSelectInputs(inputsArray, item);
    const ingredientsForm = parseIngredientsFormInputs(inputsArray, item);
    const mealsForm = parseMealsFormInputs(inputsArray, item);
    const sizesForm = parseSizesFormInputs(inputsArray, item);
    const othersForm = parseOthersFormInputs(inputsArray, item);
    const measuresForm = parseMeasurementsFormInputs(inputsArray, item);

    return {
      [idProperty]: item[idProperty],
      ...baseInputs,
      ...normalInputs,
      ...selectInputs,
      ...ingredientsForm,
      ...mealsForm,
      ...sizesForm,
      ...measuresForm,
      ...othersForm,
      timestamp: item.timestamp,
    };
  }

  static toColumns(
    inputs: FormInputContent,
    baseCols?: Array<any>,
    extraInfo?: any
  ) {
    const columnHelper = createColumnHelper<any>();
    const columns = Object.entries<FormInputContent>(inputs)
      .filter(([key]) => key !== 'sectionProperties')
      .filter(([key]) => key !== 'others')
      .filter(
        ([_, value]) => value?.sectionProperties?.sectionType !== 'resume'
      )
      .reduce((res: any, [key, value]) => {
        if (Object.keys(value).includes('sectionProperties')) {
          return [
            ...res,
            columnHelper.group({
              header: value.sectionProperties.title,
              columns: Object.keys(value)
                .map((subKey) => {
                  const header = {
                    header: value[subKey]?.label,
                    id: `${key}-${subKey}`,
                  };

                  const columnMapper = {
                    ingredientsForm: ingredientsToString,
                    mealsForm: mealsToString,
                    sizesForm: sizesToString,
                    measuresForm: measuresToString,
                    normal: undefined,
                    others: undefined,
                    resume: undefined,
                  };

                  if (subKey === 'sectionProperties') {
                    const {
                      sectionProperties: { sectionType },
                    } = value;

                    const parser =
                      columnMapper[
                        sectionType as
                          | 'ingredientsForm'
                          | 'mealsForm'
                          | 'sizesForm'
                          | 'measuresForm'
                          | 'normal'
                          | 'others'
                          | 'resume'
                      ];
                    if (parser) {
                      return columnHelper.accessor(
                        (data) => parser(data?.[key], extraInfo?.foods),
                        { ...header }
                      );
                    }

                    return undefined;
                  }

                  if (value[subKey].component === 'multiselect') {
                    return columnHelper.accessor(
                      (data) =>
                        data?.[key]
                          ? value[subKey]?.options
                              .filter(({ value: subValue }: any) =>
                                Object.keys(data?.[key]).includes(subValue)
                              )
                              .map(({ label }: any) => label)
                              .join(', ')
                          : '',
                      {
                        ...header,
                      }
                    );
                  }

                  return columnHelper.accessor(
                    (data) => data?.[key]?.[subKey],
                    {
                      ...header,
                    }
                  );
                })
                .filter((val) => val !== undefined) as any,
            }),
          ];
        }

        return [
          ...res,
          columnHelper.accessor((data) => data?.[key], {
            header: value.label,
            id: key,
          }),
        ];
      }, baseCols ?? []);

    return columns;
  }
}
