import { Control } from "react-hook-form"
import MultiSelect from "../../../components/MultiSelect";
import { mealsOptions } from "./inputs";

interface Props {
  control: Control;
  defaultValues: any;
};

const MealsForm = ({ control, defaultValues }: Props) => {
  return <MultiSelect name="meals" options={mealsOptions} control={control} defaultValues={defaultValues} />
}


export default MealsForm;