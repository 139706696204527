import { HttpClient } from '../http/HttpClient';
import * as jose from 'jose';

export type UserCognitoAttributes = {
  'custom:attributo1': string;
  sub: string;
  email: string;
  name: string;
};

export type UserCognitoUpdateAttributes = {
  name?: string;
};

export class UserService {
  private client: HttpClient;

  constructor(client: HttpClient) {
    this.client = client;
  }

  async getAttributes(): Promise<any> {
    const baseUrl =
      'https://33fxnfac74nwhow5zbydqelhx40moqzz.lambda-url.sa-east-1.on.aws/';
    const { data }: { data: Array<{ Name: string; Value: string }> } =
      await this.client.get(`${baseUrl}`, {});
    const userAttributes: UserCognitoAttributes = data.reduce(
      (res, { Name, Value }) => ({ ...res, [Name]: Value }),
      {} as UserCognitoAttributes
    );

    return {
      id: userAttributes.sub,
      name: userAttributes.name,
      email: userAttributes.email,
    };
  }

  async updateAttributes(userInfo: UserCognitoUpdateAttributes) {
    const baseURL =
      'https://ovza3nrr3uqshxk32wylp2rnbq0aaalc.lambda-url.sa-east-1.on.aws/';
    return this.client.put(baseURL, userInfo, {});
  }

  getGroups(token: string): Array<string> {
    const groups = jose.decodeJwt(token)['cognito:groups'] as
      | Array<string>
      | undefined;
    return groups ?? [];
  }
}
