import { Control, UseFormRegister } from "react-hook-form";
import { BasicFormComponents, FormInput } from "../../types";

interface Props {
  content: FormInput;
  register: UseFormRegister<any>;
  control: Control;
  components: BasicFormComponents;
  defaultValues: any;
}

const Form = ({ content, register, control, components, defaultValues }: Props) => Object.entries<any>(content).filter(([name]) => name !== 'sectionProperties').map(
  ([name, { label, component, options, type, ...registerOptions }]) => <div className="mb-5.5 flex flex-col gap-5.5 sm:flex-row">
    <div className="w-full">
      <label
        className="mb-3 block text-sm font-medium text-black dark:text-white"
        htmlFor={name}
      >
        {label}
      </label>
      {components[component as keyof BasicFormComponents ?? 'input']({ defaultValues, control, options, ...register<any>(name, registerOptions), type })}
    </div>
  </div>
);

export default Form;