import BasicForm from "../../../components/BasicForm";
import { inputs } from "./inputs";
import { useEditItem } from "../../../hooks/useEditItem";
import { portionService } from "../../../core/services";

const PortionsRegistry = () => {
  const { onSubmit, defaultValues } = useEditItem('pid', portionService, inputs);

  return <BasicForm
    title={'Registro de porciones'}
    inputs={inputs}
    onSubmit={onSubmit}
    defaultValues={defaultValues}
  />;
};

export default PortionsRegistry;