import { Control, Controller } from "react-hook-form";
import { SelectOptions } from "../../types";
import ReactSelect from 'react-select'

interface Props {
  name: string;
  options: SelectOptions;
  control: Control;
  defaultValues?: any;
};

const Select = ({ name, options, control, defaultValues }: Props) => {
  return <Controller
    control={control}
    name={name as any}
    render={({ field: { onChange, value, ref } }) => (
      <ReactSelect
        ref={ref}
        className="mb-4"
        defaultValue={defaultValues}
        value={options.find((c) => c.value === value)}
        onChange={(option) => onChange(option)}
        options={options}
      />
    )}
  />
}

export default Select;