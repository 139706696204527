import { useContext } from 'react';
import { FoodListContext } from '../providers/FoodListProvider';

export const useIngredientsOptions = (defaultValues?: any) => {
  const { foods } = useContext(FoodListContext);
  const options = foods.map(
    ({ fid, identification }: { fid: string; identification: any }) => ({
      label: identification.name_specific,
      value: fid,
    })
  );
  if (defaultValues) {
    const defaultValuesMapped = defaultValues.map(([id, value]: any) => {
      const foodName = options.find(({ value }: any) => value === id)?.label;
      return [foodName, value, id];
    });

    return { defaultValuesMapped, options };
  }
  return { options };
};
